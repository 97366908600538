import i18n from '@/plugins/i18n'
import RouterOption from '@/models/RouterOption'
import AffiliatedWrapper from '@/views/affiliated/AffiliatedWrapper.vue'
import Documents from '@/views/affiliated/Documents.vue'
import SignUp from '@/views/affiliated/SignUp.vue'
import TabFAQView from '@/views/faq/TabFAQView.vue'
import Password from '@/views/signIn/Password.vue'
import SignIn from '@/views/signIn/SignIn.vue'
import LandingMain from '@/views/landing/LandingMain.vue'
import HomeMain from '@/views/home/HomeMain.vue'
import RecipientParent from '@/views/recipient/RecipientParent.vue'
import CsWrapper from '@/views/cs/CsWrapper.vue'
import TransferParent from '@/views/transfer/TransferParent.vue'
import TransferHistory from '@/views/TransferHistory.vue'

const Routes: Array<RouterOption> = [
  {
    path: '/',
    show: false,
    component: LandingMain,
    beforeEnter(to, from, next) {
      if (process.env.NODE_ENV !== 'development') {
        window.location.href = process.env.VUE_APP_LANDING_PAGE
      } else {
       let result = window.confirm('개발사이트 입니다. 페이지 이동을 원하지 않으시면 확인 버튼을 눌러주세요.')
       if (result) {
        next()
       } else {
        window.location.href = process.env.VUE_APP_LANDING_PAGE
       }
      }
    }
  },
  {
    label: '로그인',
    path: '/signIn',
    show: false,
    component: SignIn
  },
  { label: '비밀번호 등록', path: '/password', show: false, component: Password },
  { label: '기업 가입', path: '/signUp', show: false, component: SignUp },
  {
    label: `${i18n.t('menu.home')}`,
    name: 'Dashboard',
    path: '/home',
    show: true,
    type: 'nav',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"><g fill="none" fill-rule="evenodd"><g fill="#8D8D8D" fill-rule="nonzero"><path d="M7.581.15l6.429 5c.174.136.276.344.276.564v7.857c0 1.184-.96 2.143-2.143 2.143H9.5v-1.429h2.643c.394 0 .714-.32.714-.714V6.064L7.143 1.619 1.429 6.064v7.507c0 .395.32.715.714.715L5 14.285v1.429H2.143C.959 15.714 0 14.754 0 13.571V5.714c0-.22.102-.428.276-.564l6.428-5c.258-.2.62-.2.877 0z" transform="translate(-42 -192) translate(42 192)"/><path d="M8.571 9.881v5.25c0 .322.32.583.715.583.394 0 .714-.26.714-.583V9.298c0-.323-.32-.584-.714-.584H5c-.394 0-.714.261-.714.584v5.833c0 .322.32.583.714.583.394 0 .714-.26.714-.583v-5.25h2.857z" transform="translate(-42 -192) translate(42 192)"/></g></g></svg>',
    component: HomeMain
  },
  {
    label: `${i18n.t('menu.transfer')}`,
    path: '/transfer',
    show: true,
    type: 'nav',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="12" viewBox="0 0 14 12"><defs><path id="7r4ag7yjxa" d="M7.97.97c.293-.293.767-.293 1.06 0l4.5 4.5c.025.024.048.05.069.078l.023.032.031.051.023.044c.009.017.017.036.024.055l.011.031.02.067.007.036c.008.043.012.09.012.136l-.004-.073c.002.014.003.029.003.044L13.75 6v.03l-.004.044c-.002.022-.005.043-.009.063l-.007.034c-.005.024-.012.046-.019.068l-.011.03c-.007.02-.015.039-.024.057l-.023.043c-.01.018-.02.035-.032.051-.026.039-.056.076-.09.11l.068-.078-.005.006-.064.072-4.5 4.5c-.293.293-.767.293-1.06 0-.293-.293-.293-.767 0-1.06l3.22-3.221L1 6.75c-.38 0-.693-.282-.743-.648L.25 6c0-.414.336-.75.75-.75l10.188-.001L7.97 2.03c-.267-.266-.29-.683-.073-.976z"/></defs><g fill="#8D8D8D" fill-rule="evenodd"><g transform="translate(-42 -258) translate(42 258)"><use fill-rule="nonzero" xlink:href="#7r4ag7yjxa"/></g></g></svg>',
    component: TransferParent,
    children: [
      // manual
      {
        label: `${i18n.t('menu.transfer_select')}`,
        path: 'selectRecipients',
        show: true,
        component: () => import('@/views/transfer/SelectRecipientManually.vue')
      },
      {
        label: `'송금 직접 선택'`,
        path: 'manualApply',
        show: false,
        component: () => import('@/views/transfer/ManualApply.vue')
      },
      // sheet upload
      {
        label: `${i18n.t('menu.transfer_excel')}`,
        path: 'sheet/upload',
        show: true,
        component: () => import('@/views/transfer/TransferUpload.vue')
      },
      {
        label: '송금 신청 필터',
        path: 'sheet/invalidApply',
        show: false,
        component: () => import('@/views/transfer/InvalidApply.vue')
      },
      {
        label: '송금 신청',
        path: 'sheet/apply',
        show: false,
        component: () => import('@/views/transfer/Apply.vue')
      },
      // register
      {
        label: '고액 송금 신청',
        path: 'analogue',
        show: false,
        component: () => import('@/views/transfer/AnalogueApply.vue')
      },
      { label: '송금 등록', path: 'register', show: false, component: () => import('@/views/transfer/Register.vue') },
      {
        label: '송금 완료',
        path: 'registered',
        show: false,
        component: () => import('@/views/transfer/Registered.vue')
      },
      // sheet download
      {
        label: `${i18n.t('menu.excel_download')}`,
        path: 'sheet/download',
        show: true,
        component: () => import('@/views/transfer/TransferDownload.vue')
      }
    ]
  },
  {
    label: `${i18n.t('menu.transfer_history')}`,
    path: '/history',
    show: true,
    type: 'nav',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10"><g fill="#8D8D8D" fill-rule="evenodd"><g transform="translate(-42 -323) translate(42 323)"><rect width="13.5" height="1.4" rx=".7"/><rect width="13.5" height="1.4" y="4.15" rx=".7"/><rect width="9" height="1.4" y="8.3" rx=".7"/></g></g></svg>',
    component: TransferHistory
  },
  {
    label: `${i18n.t('commons.recipient')}`,
    path: '/recipient',
    show: true,
    type: 'nav',
    component: RecipientParent,
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="16" viewBox="0 0 14 16"><defs><path id="cht4pfzita" d="M9.333 8.917c1.956 0 3.542 1.585 3.542 3.541v1.417c0 .391-.317.708-.708.708-.392 0-.709-.317-.709-.708v-1.417c0-1.173-.951-2.125-2.125-2.125H3.667c-1.174 0-2.125.952-2.125 2.125v1.417c0 .391-.317.708-.709.708-.39 0-.708-.317-.708-.708v-1.417c0-1.956 1.586-3.541 3.542-3.541zM6.5.417c1.956 0 3.542 1.585 3.542 3.541S8.456 7.5 6.5 7.5c-1.956 0-3.542-1.586-3.542-3.542C2.958 2.002 4.544.417 6.5.417zm0 1.416c-1.174 0-2.125.952-2.125 2.125 0 1.174.951 2.125 2.125 2.125s2.125-.951 2.125-2.125c0-1.173-.951-2.125-2.125-2.125z"/></defs><g fill="#8D8D8D" fill-rule="evenodd"><g transform="translate(-42 -384) translate(42 384) translate(.5 .5)"><use fill-rule="nonzero" xlink:href="#cht4pfzita"/></g></g></svg>',
    children: [
      {
        label: `${i18n.t('menu.recipients_list')}`,
        path: 'recipients',
        show: true,
        type: 'nav',
        component: () => import('@/views/Recipients.vue')
      },
      {
        label: `${i18n.t('menu.recipients_register')}`,
        path: 'enlist',
        show: true,
        component: () => import('@/views/recipient/RegisterSelector.vue')
      },
      {
        label: `'등록 필터'`,
        path: 'invalidRegister',
        show: false,
        component: () => import('@/views/recipient/InvalidRegister.vue')
      },
      {
        label: '등록하기',
        path: 'register',
        show: false,
        component: () => import('@/views/recipient/Register.vue')
      }
    ]
  },
  {
    label: `${i18n.t('dome.main')}`,
    path: '/dome',
    show: true,
    type: 'nav',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="26" viewBox="5 0 16 26" fill="none">\n' +
      '<path d="M13 20C16.866 20 20 16.866 20 13C20 9.13401 16.866 6 13 6C9.13401 6 6 9.13401 6 13C6 16.866 9.13401 20 13 20Z" stroke="#8D8D8D" stroke-width="1.3" stroke-miterlimit="10"/>\n' +
      '<path d="M6 13H20" stroke="#8D8D8D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '<path d="M13.0002 19.8117C14.611 19.8117 15.9168 16.7618 15.9168 12.9996C15.9168 9.23738 14.611 6.1875 13.0002 6.1875C11.3893 6.1875 10.0835 9.23738 10.0835 12.9996C10.0835 16.7618 11.3893 19.8117 13.0002 19.8117Z" stroke="#8D8D8D" stroke-width="1.3" stroke-miterlimit="10"/>\n' +
      '</svg>',
    component: () => import('@/dome/container/DomeTransactionalInfo/DomeTransactionalInfo.vue')
  },
  {
    label: `${i18n.t('menu.manage_members')}`,
    path: '/account',
    show: false,
    type: 'nav',
    component: () => import('@/views/user/AccountManagement.vue')
  },
  {
    label: 'Wallet',
    path: '/wallet/:currency',
    show: true,
    type: 'sub',
    component: () => import('@/views/wallet/WalletView.vue')
  },
  {
    label: `${i18n.t('commons.policies_title')}`,
    path: '/policy',
    show: true,
    component: CsWrapper,
    type: 'footer',
    children: [
      {
        label: `${i18n.t('commons.sentbiz_introduce')}`,
        path: '/',
        target: '_blank',
        show: true
      },
    ]
  },
  {
    label: '랜딩FAQ',
    path: '/tabFaq',
    show: false,
    component: TabFAQView
  },
  {
    label: '제휴 신청',
    path: '/affiliate',
    show: false,
    component: AffiliatedWrapper,
    redirect: '/affiliate/documents',
    children: [
      { label: '가입 문서 작성', path: 'documents', show: false, component: Documents },
      { label: '다운로드', path: 'download', show: false, component: () => import('@/views/affiliated/Download.vue') },
      { label: '업로드', path: 'upload', show: false, component: () => import('@/views/affiliated/Upload.vue') },
      { label: '계정 추가', path: 'account', show: false, component: () => import('@/views/affiliated/Account.vue') }
    ]
  },
  {
    path: '*',
    redirect: '/',
    show: false
  }
]

export default Routes
