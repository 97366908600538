import { render, staticRenderFns } from "./NaviFooterWrapper.vue?vue&type=template&id=b8ee87d0&scoped=true"
import script from "./NaviFooterWrapper.vue?vue&type=script&lang=ts"
export * from "./NaviFooterWrapper.vue?vue&type=script&lang=ts"
import style0 from "./NaviFooterWrapper.vue?vue&type=style&index=0&id=b8ee87d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ee87d0",
  null
  
)

export default component.exports