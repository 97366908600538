enum RecipientFieldEnum {
  CORPS_ID = 0, // CLIENT HAS ONLY
  MASTER_CODE = 1,
  NAME_FIRST = 2,
  NAME_MIDDLE = 3,
  NAME_LAST = 4,
  NAME_FULL = 5,
  PHONE = 6,
  BANK_BRANCH = 7,
  ACCOUNT_NUMBER = 8,
  ACCOUNT_TYPE = 9,
  CARD_NUMBER = 10,
  ID_CARD_NUMBER = 11,
  ID_CARD_TYPE = 12,
  ID_CARD_DATE_OF_ISSUE = 13,
  ID_CARD_PLACE_OF_ISSUE = 14,
  ADDRESS_LINE1 = 15,
  ADDRESS_LINE2 = 16,
  ADDRESS_POSTAL = 17,
  ADDRESS_REGION = 18,
  ADDRESS_CITY = 19,
  IBAN = 20,
  ROUTING_NUMBER = 21,
  IFSC = 22,
  BSB_CODE = 23,
  SWIFT = 24,
  SORT_CODE = 25,
  WINGMONEY_NUMBER = 26,
  TRANSIT_CODE = 27,
  CORRESPONDING_BANK_SWIFT = 28,
  EMAIL = 30,
  BIRTH_DATE = 31,
  ID_CARD_EXPIRE = 32,
  NATION = 33,
  PHONE_CODE = 100, // CLIENT HAS ONLY
  BASE_CURRENCY// CLIENT HAS ONLY
}

export default RecipientFieldEnum
